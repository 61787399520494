@charset "utf-8";

@import "nilq-mixin";


.lead {
  line-height: 1.6em;
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 18px;
  }
}

.col-md-4, .col-md-12 {
  padding: 0px 40px;
}

.col-xs-12 {
  padding: 0px 20px;
}

body {
  font-family:'Lucida Grande','Hiragino Kaku Gothic ProN',Meiryo, sans-serif;
}

.container1 h3 {
  margin-top: 1em;
  margin-bottom: 1em;
}

.glyphicon {
  font-size: 64px;
}

.h2 {
  margin-bottom: 20px;
}
/*
 carousel
---------*/

.carousel-wrapper {
  width: 100%;
  margin: 0 auto;
//  margin-top: 20px;
}

//div#carousel-example-generic {
//  width: 90%;
//  margin: 0 auto;
//}

.carousel-control.left {
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
  background-repeat: repeat-x;
}
.carousel-control.right {
  right: 0;
  left: auto;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
  background-repeat: repeat-x;
}



.navbar {
  margin-bottom: 0px;
  .icon {
    margin-right: 15px;
    margin-bottom: 5px;
  }
  .navbar-header a {
    color: rgb(51, 51, 51);
    text-decoration: none;
  }
}

ul.nav {
  margin: 20px 0 10px 20px;
}


// Features
.tagline {
  padding-top: 30px;
  text-align: center;
  @media (min-width: 768px) {
    //font-size: 1.5em;
  }
  margin-bottom: 20px;
}

.container1{
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  background-color: #f7f7e0;
}

.features {
  padding-bottom: 30px;
  .button-wrapper {
    padding: 10px 20% 0 0;
    text-align: right;
  }
  .col-md-5, .col-xs-12 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  img{
    width: 64px;
    height: 64px;
  }
}

.media-body {
  width: 300px;
  padding: 20px 0 0 10px;
}

// Mail Regist
#mail-reg {
  margin-top: 50px;
  margin-bottom: 30px;
}

@media (min-width: 768px){
  .form-inline #inputEmail {
      width: 500px;
      margin-right: 20px;
  }
}

// Limited Member
.limited-member {
  margin: 30px 0;
}


// Facilities
.facilities{
  h4 {
    @include h4-mod;
  }
  .facility1{
    margin-bottom: 30px;
  }
  .facility2{
    margin-bottom: 30px;
  }
}

@media (min-width: 768px){
.facilities{
  .facility1{
    margin-bottom: 40px;
    h4 {
      @include h4-mod;
      margin-left: 20px;
      margin-right: 40px;
    }
    p{
      margin-left: 20px;
      margin-right: 40px;
    }
  }

  .facility2{
    h4 {
      @include h4-mod;
      margin-left: 40px;
      margin-right: 20px;
    }
    p{
      margin-left: 40px;
      margin-right: 20px;
    }
  }
}
}

// Pricing
.pricing {
  .space{
    margin-bottom: 30px;
    tr > th:nth-child(1){
      width: 5%
    }
  }
  img{
    margin: 6px 0 0 12px;
    width: 48px;
    height: 48px;
  }
  h4{
    @include h4-no-dotted;

  }
}

// Calendar
.working-hour{
  margin-bottom: 30px;
}

// for Google Calendar
.cal_wrapper {
  max-width: 840px;
  margin: 2.0833% auto;
}

.calendar-container {
  position: relative;
  padding-bottom: 100%;
  min-height: 540px;
}
.calendar-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

#map {
  height: 300px;
  width: 100%;
}


#map1 {
  height: 300px;
  width: 100%;
}

#map img{
  max-width : none;
}

#map label{
  width : auto;
  display : inline;
}

/*
 news
---------*/

.news-date {
  width: 10.5em;
  margin-left: 0.5em;
}

.newmark {
  text-align: center;
  width: 3.6em;
  color: #ffffff;
  background-color:#EF4907;
  border: solid #EF4907;
  padding: 0 0.5em;
  margin-left: 0.75em;
}

.no-newmark {
  text-align: center;
  width: 3.6em;
  color: #ffffff;
  background-color:#ffffff;
  border: solid #ffffff;
  padding: 0 0.5em;
  margin-left: 2em;
}

.news-text {
  margin-left: 1em;
}

.news ul {
  margin-bottom: 0.5em;
}


/*
 footer
---------*/

.footer {
  padding: 20px 0;
  background-color: #eee;
}

.footer {
  width: 100%;
  .footer-main {
    .footer-title {
      font-size: 1.5em;
    }
    .footer-info {
      margin-top: 1em;
      margin-bottom: 1em;
    }
    ul {
      margin-top: 20px;
      text-align: left;
      li {
        margin-top: 1em;
        margin-bottom: 1em;
      }
    }
  }
}


.link-icons {
  width: 100%;
  ul{
    text-align: center;
  }
  li{
    margin: 0 0 0 20px;
  }
}

.copyright-wrapper {
  margin-top: 20px;
  @media (min-width: 768px) {
    margin-top: 10px;
  }

  width: 100%;
  .copyright {
    font-size: 80%;
    text-align: center;
  }
}

// Utilites
// 取り消し線を指定するcss
.line-through {
  text-decoration: line-through;
}